<template>
  <div class="page">
    <div class="page__title">
     {{ getModuleConfig("my_page.form_title") }}
    </div>
    <div
      class="page__desc"
      v-html="getModuleConfig('my_page.form_desc')"
    ></div>
    <div  class="page__content" v-if="getModuleConfig('my_page.content')" v-html="getModuleConfig('my_page.content')"></div>
    <div>
      <Loading v-if="showLoading" loading-message="載入中..." />
      <coupon-card
        v-else-if="!showLoading && coupons.length > 0"
        v-for="coupon in coupons"
        :key="coupon.id"
        :coupon="coupon"
        :show-code="false"
        :button-text="coupon.button_text"
        @exchange="goToDetail(coupon)"
      >
      </coupon-card>
      <coupon-empty v-else />
      <!-- <Pagination v-model="page" :total-page="lastPage" /> -->
    </div>
  </div>
</template>

<script>
import abbottAndApi from "@/apis/liff/v2/proj/abbott-and";
import CouponCard from "@/pages/Dashboard/Coupon/CouponCard.vue";
import CouponEmpty from "@/pages/Dashboard/Coupon/CouponEmpty.vue";
import Loading from "@/components/Page/Liff/Shared/Loading";
import couponMixin from "@/mixins/liff/coupon";


export default {
  mixins: [couponMixin],
  components: { CouponCard, CouponEmpty, Loading },
  data() {
    return {
      showLoading: true,
      code: null,
      coupons: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.fetchCoupon();
  },
  methods: {
    async fetchCoupon() {
      this.showLoading = true;
      await this.getCoupons();
      this.showLoading = false;
    },
    goToDetail(coupon) {
      this.$router.push({
        name: "LiffProjAbbottAndCouponDetail",
        params: {
          orgCode: this.$route.params.orgCode,
          id: coupon.id,
        },
      });
    },
    async getCoupons() {
      try {
        const { data } = await abbottAndApi.getCouponList(this.$route.params.orgCode,
          {
            status: 1,
          }
        );
        this.coupons = data.data.map((item) => {
          return {
            ...item,
            is_enabled: true
          };
        });
      } catch (error) {
        if (error.response.data.message) {
          this.$swal.fire({
            type: "error",
            title: "錯誤",
            text: error.response.data.message,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.search {
  display: flex;

  &__input {
    background: #ffffff;
    border-radius: 10px !important;
    border: 1px solid var(--liff-button-color);
    padding: 15px 12px;
    width: 80%;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;

    &::placeholder {
      letter-spacing: -0.408px;
      color: #b7b7b7;
    }

    &:focus {
      border: 1px solid var(--liff-button-color);
      outline: none;
    }
  }

  button {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    height: 48px;
    width: 20%;
  }
}
</style>
